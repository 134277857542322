/* energyStyles.css */
.energy-root {
  display: flex;
  width: 100%;
}

.energy-drawer {
  width: 240px;
  flex-shrink: 0;
}

.energy-drawerPaper {
  width: 240px;
  top: 65px; /* Adjust to move drawer content down by 40px */
}

.energy-content {
  padding: 20px; /* Adjust padding as needed */
  width: 100%; /* Ensure the main content container takes up the entire width */
}

.energy-plant-name {
  text-align: left;
  font-size: medium;
  color: rgb(249, 87, 255);
  padding-left: 20px;
}


