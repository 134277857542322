.container {
  padding: 1rem;
  width: 100%;
  display: grid;
  grid-template-rows: auto 1fr;
}

.controlsContainer {
  height: 60px;
}

.alertContainer {
  height: calc(100% - 60px);
  overflow: auto;
}
