/* DeviceTableManagerStyles.css */

.tableContainer {
  min-Height: 200px;
  max-height: calc(100vh - 300px);
  overflow-y: auto;
}

.stickyHeader {
  position: sticky;
  top: 0;
  background-color: white; /* Adjust as needed */
  z-index: 1; /* Ensure it stays above other content */
}
