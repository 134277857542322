.container{
  width:100%;
  height:100%;
  display:grid;
  grid-template-rows: auto 1fr;
  gap: 5px;
  position: relative;
  *{
    user-select: none;
  }
}

.localControlsContainer{
  width: 100%;
  .localControls{
    display: flex;
    align-items: center;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }
}

.loading{
  &:before{
    content:'';
    width:100%;
    height:97%;
    top:0px;
    left:0px;
    z-index: 2;
    background-color: rgba(18, 18, 18, 0.8);
    position: absolute;
  }
  .loadingElement{
    position: absolute;
    width:100%;
    height:100%;
    display:grid;
    gap:10px;
    z-index: 3;
    place-content: center;
    place-items: center;
    p{
      font-size: 1.2em;
      margin:0;
      padding:0;
    }
    img{
      width:75px;
      height:75px;
    }
  }
}



