// TODO: Talk to Daniel about --variables for theme.
:root {
  --border-color: black;
}

.healthy {
  --primary-color: rgb(2, 213, 104);
  --secondary-color: rgb(0, 243, 117);
  --text-color: rgb(2, 195, 95);
}

.unhealthy {
  --primary-color: #d32f2f;
  --secondary-color: #fa3939;
  --text-color: #cf2f2f;
}

.isRegistered {
  --border-color: white;
}

.notRegistered {
  color: rgba(white, 0.2);
}

.card {
  position: relative;
  margin: 0;
  list-style: none;
  display: flex;
  padding: 0.15rem;
  background-image: linear-gradient(45deg, var(--primary-color), var(--secondary-color) 30%, var(--border-color) 60%);
  overflow-wrap: break-word;
  background-size: 400%;
  border-radius: 0.5rem;
  background-position: 100%;
  transition: background-position 0.6s cubic-bezier(0.22, 1, 0.36, 1);
}

.card > a {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  text-decoration: none;
  line-height: 1.4;
  padding: 1em 1.3em;
  border-radius: 0.35rem;
  background-color: rgb(28, 28, 28);
  opacity: 0.95; // Normally I go with 0.8 here, but I feel like the app is too dark to play around with opacities. If we
  // make the web-app a little brighter it will open up a lot of creative possibilities.
}

.card > a h3 {
  color: var(--secondary-color);
}

.card:is(:hover, :focus-within) {
  background-position: 0;
}
.card:is(:hover, :focus-within) h3 {
  color: var(--text-color);
}
.card:is(:hover, :focus-within) h2 {
  color: var(--text-color);
}
.card:is(:hover, :focus-within) h2 span {
  transform: translateX(2px);
}
