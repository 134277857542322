.container {
  padding: 1rem;
  width: 100%;
  display: grid;
  grid-template-rows: auto 1fr;
}

.box {
  display: flex;
  flex-flow: column;
  height: 100%;
  flex: 1 1 auto;
}

.logBox {
  color: white;
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  background-color: rgb(28, 28, 28);
  border-color: gray;
  border-style: dashed;
  line-height: 1.4;
  padding: 0.15rem;
  margin-top: 0.5rem;
  height: 100%;
  scroll-behavior: smooth;
  overflow-y: hidden;
  position: relative;
  width: 79%;
}

.log {
  padding: 0.5em 1.3em;
  margin: 0;
}

.plantInfoBox {
  position: absolute;
  right: 0;
  width: 19%;
}

.searchBar {
  display: flex;
  gap: 1em;
  align-items: center;
  width: 79%;
}

.commandBar {
  display: flex;
  gap: 1em;
  align-items: center;
  margin: 1rem;
  margin-top: 0.35rem;
  width: 79%;
}

.redPiDeviceList {
  display: block;
  position: relative;
  margin-top: 20;
}

.resetFiltersContainer {
  display: grid;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #121212;
  z-index: 100;
  place-content: center;
  place-items: center;
}

.deviceCardGrid {
  display: grid;
  grid-template-columns: repeat(7, minmax(24ch, 1fr));
  gap: 1rem;
  padding: 0;
  padding-right: 1rem;
}

.refreshButton {
  height: 3em;
}

.healthy {
  color: rgb(2, 195, 95);
}

.unhealthy {
  color: #cf2f2f;
}

.pageSelector {
  display: flex;
  gap: 0.5em;
  align-items: center;
  margin-right: 0;
  margin-left: auto;
}

.arrowButton {
  min-width: 0;
  border: 0.1em;
  border-style: solid;
}

.commandModalLink {
  background-color: rgba(white, .5);
}