.body{
  background-color: #f8f8f8;
  color:black;
  padding:10px;
  position: relative;
  z-index: 10000;
}

.rowTitle{
  font-weight: bold;
  font-size: 1em;
  margin:unset;
  padding:unset;
}

.row{
  display: inline-flex;
  grid-auto-flow: column;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  height: 30px;
  .lineColourIndicator{
    width:10px;
    height:5px;
  }
}