.container {
  display: flex;
  padding: 2rem;

}

.pageHeader {
  position: relative; /* Changed from sticky to relative */

}

.pageSelector {
  display: flex;
  flex: 1;
  justify-content: flex-end;
}

.box {
  display: flex;
  flex-flow: column;
  height: 100%;
  width: 95%;
  justify-self: center;
  top: 0%;
}

.logBox {
  color: white;
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  background-color: rgb(28, 28, 28);
  border-color: gray;
  border-style: dashed;
  line-height: 1.4;
  padding: 0.15rem;
  margin-top: 0.5rem;
  height: 100%;
  scroll-behavior: smooth;
  overflow-y: hidden;
  position: relative;
}

.log {
  display: grid;
}
.logSummary {
  display: grid;
  grid-template-columns: repeat(3, minmax(24ch, 32ch));
}
.logDetails {
  display: grid;
  grid-template-columns: repeat(2, 50%);
}

.logBar {
  display: flex;
  gap: 1em;
  align-items: center;
}

.houseContainer {
  width:100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 270px; /* Adjust as needed */
  flex-direction: column;
  
}

.factoryContainer {
  width:100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 270px; /* Adjust as needed */
  flex-direction: column;
  
}

.image {
  width: 350px;
  height: 250px;
  object-fit: contain; /* Ensure the image covers its container */
}

.svgContainer {
  width:100%;
  position: absolute;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.rounded-progress {
  height: 20px;
  border-radius: 10px; /* Rounded edges */
}

.rounded-progress .MuiLinearProgress-bar {
  border-radius: 10px; /* Rounded edges for the progress bar */
  background-color: #1a90ff; /* MUI primary color */
}
