.graphContainer{
  width:inherit;
  padding:20px;
  margin-bottom: 200px; //this magin is here so if a tooltip is floating below the chart it doesnt cause overflow issues
  .graphViewContainer{
    height:100%;
    max-height: 100vh;
  }
}

.iconFieldContainer{
  width:100%;
  height: calc(100vh - 70px);
}


.valueBox {
  border-radius: 10px;
  padding: 10px 15px; // Reduce padding to make boxes less tall
  display: flex;
  flex-direction: row;
  min-width: 300px;
  justify-content: space-between;
  align-items: center;

}

.valueBoxDashboard
{
  border-radius: 10px;
  padding: 16px 16px; // Reduce padding to make boxes less tall
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 17.8px;
}


.valueBoxMiddle {
  border-radius: 10px;
  padding: 10px 15px; // Reduce padding to make boxes less tall
  display: flex;
  flex-direction: column;
  min-width: 300px;
  height: 250px;
  justify-content: flex-start;
  align-items: flex-start;
  background-size: cover;
  background-position: center;
}

.valueBoxBottom{
  border-radius: 10px;
  padding: 10px 10px; // Reduce padding to make boxes less tall
  display: flex;
  flex-direction: column;
  min-width: 300px;
  justify-content: flex-start;
  align-items: flex-start;
  max-height: 100vh;
  height:100%;
}




.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex-grow: 1;
  max-width: fit-content;
  min-width: max-content;
}

.icon {
  margin-left: auto;
  font-size: 24px; // Increase icon size
}
